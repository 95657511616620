import React from 'react'
import './About.scss'

// Import MUI components
import { Typography, Box, Card, CardContent } from '@mui/material';

import { useLocation } from 'react-router-dom';


const About = () => {

  // Used for the navigation to specific elements because just using the "href" attribute only works in Firefox for some reason
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);



  return (
    <Box sx={{ marginTop: '2em', textAlign: 'center' }}>

      <Box>
        <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', marginBottom: '2em' }}>
          Hochbegabt — "highly gifted or talented"
        </Typography>
      </Box>

      <Box>
        <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
          The 3 Step Process
        </Typography>

        <Card id="discuss" sx={{ mx: '5%', marginBottom: '5em', border: 1, borderColor: 'primary.main' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
              Discuss
            </Typography>
            <Typography gutterBottom component="div" sx={{ textAlign: 'center' }}>
              At Hochbegabt Technology, our discussions around software solutions are more than just meetings; they are dynamic exchanges of ideas, expertise, and innovation. 
              The process begins with a comprehensive understanding of the client's objectives, industry landscape, and target audience. Through active listening and probing questions, we delve deep into the core of their business, uncovering pain points and opportunities for growth. Each member of our team brings a wealth of experience and diverse perspectives to the table, enriching the discussion with insights and creative approaches.
              <br/><br/>
              Throughout the process, we leverage our technical expertise and industry best practices to craft robust and scalable solutions. From architecture design to technology stack selection, every decision is carefully considered to ensure optimal performance, security, and maintainability.
              As the discussion progresses, we transition from ideation to planning, outlining a clear roadmap and timeline for implementation. Transparent communication is key, as we keep our clients informed and involved every step of the way. Our goal is not just to deliver a software solution, but to forge lasting partnerships built on trust, transparency, and mutual success.
              <br/><br/>
              In the end, our discussions culminate in the delivery of a custom software solution that exceeds expectations and drives tangible results. At Hochbegabt Technology, discussions about software solutions are not just a means to an end; they are the catalyst for innovation and transformation in an ever-evolving digital landscape.
            </Typography>
          </CardContent>
        </Card>

        <Card id="design" sx={{ mx: '5%', marginBottom: '5em', border: 1, borderColor: 'primary.main' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
              Design
            </Typography>
            <Typography gutterBottom component="div" sx={{ textAlign: 'center' }}>
              From conceptualization to deployment, our team of skilled architects, developers, and designers embark on a journey to craft custom solutions that meet the unique needs and aspirations of our clients. The process begins with a deep dive into the client's requirements, objectives, and challenges. Through comprehensive discovery sessions and stakeholder interviews, we strive to gain a holistic understanding of their business landscape, user demographics, and strategic goals. Armed with insights and empathy, we set out to design solutions that not only address immediate pain points but also anticipate future needs and aspirations.
              <br/><br/>
              Leveraging industry best practices and cutting-edge technologies, we architect robust and scalable solutions that stand the test of time. From system architecture to database design, every decision is guided by principles of performance, security, and maintainability.
              Whether it's optimizing for different devices or adhering to accessibility standards, we strive to create experiences that empower and delight users across diverse contexts.
              <br/><br/>
              Throughout the design process, collaboration is key. We work closely with our clients, soliciting feedback and validation at every stage. Through transparent communication and iterative prototyping, we ensure that our designs align seamlessly with their vision and objectives.
              We remain committed to continuous improvement, iterating and evolving our designs based on user feedback, market dynamics, and emerging trends.
            </Typography>
          </CardContent>
        </Card>

        <Card id="develop" sx={{ mx: '5%', border: 1, borderColor: 'primary.main' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
              Develop
            </Typography>
            <Typography gutterBottom component="div" sx={{ textAlign: 'center' }}>
              As a premier software contracting company, we embark on a journey of innovation and transformation, partnering with clients to turn their visions into reality.
              Our development process begins with a thorough understanding of the client's needs, goals, and aspirations. Armed with a deep understanding of the problem space, we set out to architect solutions that are as elegant as they are effective.
              <br/><br/>
              Agility is at the heart of our development philosophy. We embrace iterative, incremental development methodologies such as Agile and Scrum, allowing us to adapt and evolve in response to changing requirements and priorities. Regular sprint cycles, daily stand-ups, and continuous integration ensure that our development efforts remain focused, efficient, and on track.
              We maintain transparent, open lines of communication with our clients, keeping them informed and involved every step of the way. Consistent progress updates, demos, and feedback sessions ensure that our development efforts are aligned with their vision and objectives.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default About