import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import { useState } from 'react';
import './App.scss';

// MUI
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, CssBaseline, Box } from '@mui/material';

// Pages
import Home from './pages/Home/Home';
import ContactUs from './pages/ContactUs/ContactUs';
import About from './pages/About/About';

// Components
import HeaderNav from "./components/HeaderNav/HeaderNav";
import Footer from "./components/Footer/Footer";
import NoPage404 from "./pages/NoPage404/NoPage404";

import backgroundImage from '../src/assets/background1.jpg';
import logo from './logo.svg';
import './App.scss';


function App() {

  // MUI Theme palette
  const light = createTheme({
    palette: {
      mode: "light",
    },
  });

  const dark = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };


  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
    <CssBaseline />
    <Container maxWidth={false} id="testidhere" sx={{ backgroundImage:`url(${backgroundImage})` /*background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,6,91,1) 50%, rgba(2,0,36,1) 100%);'*/ }}>
      <div className="App">
      <Box component="div" p={5}></Box>
        {/* TODO: Dark/light mode testing — Enable when done
        <FormGroup sx={{ position: 'fixed', zIndex: 5 }}>
          <FormControlLabel 
            control={
              <Switch checked={isDarkTheme} onChange={changeTheme} />
            }
            label= {isDarkTheme ? "Dark Mode" : "Light Mode"}
          />
        </FormGroup>
        */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HeaderNav />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<ContactUs />} />
            </Route>
            <Route path="*" element={<NoPage404 />} />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </div>
    </Container>
    </ThemeProvider>
  );
}

export default App;
