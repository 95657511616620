import React from 'react'
import { Link } from 'react-router-dom';
import './SmallTile.scss'

import backgroundTest from '../../assets/earth-from-space.jpg';

// MUI components
import { Card, CardContent, CardActions, Button, Typography, Box } from '@mui/material';

/** 
 * @param icon An MUI icon relevant to the tile
 * @param link The page in which the navigation leads
 * @param title The text displayed under the icon
 * @param text The paragraph description text
 */
export interface SmallTileProps {
  icon: React.ReactNode
  link: string
  title: string
  text: string
}

/** This component is a simple link in the shape of a box
 *  with an icon and text to direct the user to a page */
const SmallTile = (props: SmallTileProps) => {

  return (
    <Card sx={{ border: 1, borderColor: 'primary.main', height: "100%", display: "flex", justifyContent: 'space-between', flexDirection: 'column' }}>
      <CardContent>
        
        <Box sx={{ textAlign: 'center' }}>
          {props.icon} 
        </Box>

        <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
          {props.title}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {props.text}
        </Typography>
        
      </CardContent>

      <CardActions>
        <Link to={props.link}>
          <Button size="small">Learn More</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default SmallTile