import React from 'react'
import './ContactUs.scss'

import { Button, Container, FormControl, Box, Select, MenuItem, SelectChangeEvent, InputLabel, Typography, TextField, Card, CardContent } from '@mui/material';

const ContactUs = () => {
  return (
    <Box sx={{ marginTop: '2em' }}>

      <Box>
        <Card id="discuss" sx={{ mx: '5%', border: 1, borderColor: 'primary.main' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
              Let's get started!
            </Typography>
            <Typography gutterBottom component="div" sx={{ textAlign: 'center' }}>
              We're thrilled that you're considering partnering with us for your software needs. Whether you have a question about our services, want to discuss a potential project, or simply want to say hello, we're here to help.
              <br/><br/>
              We look forward to hearing from you and the opportunity to collaborate on your next project!
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default ContactUs