import React from 'react'
import { Outlet, Navigate, Link, useLocation } from 'react-router-dom'
import './HeaderNav.scss'
import logo_white from '../../assets/HT_Horizontal_Wh.svg'
import logo_color from '../../assets/HT_Horizontal_Color_Text.svg'

import { Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText,
         AppBar, Toolbar, IconButton, Button, Drawer} from '@mui/material';
import { Menu } from '@mui/icons-material';

const drawerWidth = 240;

// Links to appear in both navs
const navItems = [{ item: 'Home', link: '/' },
                  { item: 'About', link: '/about' },
                  { item: 'Contact Us', link: '/contact' }];
export interface HeaderNavProps {
  window?: () => Window;
}

/** This component is a standard responsive header navigation */
const HeaderNav = (props: HeaderNavProps) => {

  const { window } = props;
  const [mobileOpen, setMobileOpen] =  React.useState(false);

  /*  Gets the current URL
   *   Use location.pathname to get the path (ex: /scan) */
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} >
      <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}
        sx={{ ml: '.4em', display: { sm: 'none' } }}>
        <Menu />
      </IconButton>

      <Divider />

      <List>
        {navItems.map(({item, link}) => (
          <ListItem key={item} disablePadding>
            <ListItemButton component={Link} to={link} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <AppBar component="nav">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} 
              sx={{ mr: 2, display: { sm: 'none' } }}>
              <Menu />
            </IconButton>
            
            <Typography variant="h6" component="div" sx={{ textAlign: 'center', flexGrow: 1, display: { sm: 'none' } }}>
              {location.pathname.toUpperCase().charAt(1) + location.pathname.substring(2)}
            </Typography>
            
            <Button component={Link} to="/">
              <img src={logo_color} alt='Hochbegabt logo' width="125" height="51" />
            </Button>

            <Box component="div" sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1, textAlign: 'left', marginLeft: '3em' }}>
              {navItems.map(({item, link}) => (
                <Button key={item} component={Link} to={link} sx={{ color: '#fff' }}>
                  {item}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="nav">
          <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>

      {/* Render child components */}
      <Outlet></Outlet>
    </div>
  )
}

HeaderNav.propTypes = {}

export default HeaderNav
