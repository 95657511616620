import React from 'react'
import './Home.scss'
import SmallTile from '../../components/SmallTile/SmallTile';

import { ReactComponent as Discuss } from '../../assets/discuss.svg';
import { ReactComponent as Angular } from '../../assets/software-icons/angular.svg';
import { ReactComponent as GraphAPI } from '../../assets/software-icons/graph-api.svg';
import { ReactComponent as Docker } from '../../assets/software-icons/docker.svg';
import { ReactComponent as DotNet } from '../../assets/software-icons/dot-net.svg';
import { ReactComponent as MaterialUI } from '../../assets/software-icons/materialui.svg';
import { ReactComponent as GraphQL } from '../../assets/software-icons/graphql.svg';
import { ReactComponent as ReactIcon } from '../../assets/software-icons/react.svg';
import { ReactComponent as SQLServer } from '../../assets/software-icons/sqlserver2.svg';
import { ReactComponent as TypeScript } from '../../assets/software-icons/typescript.svg';
import backgroundImage from '../../assets/earth-from-space.jpg';


import { Box, List, ListItem, Card, CardContent, Typography, SvgIcon, Stack, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Construction, Create } from '@mui/icons-material';

const Home = () => {

  const iconSize = 60;
  const iconSizeTech = 80;

  const frontEndIcons = [
     Angular
    ,ReactIcon
    ,TypeScript
    ,MaterialUI
  ];

  const backEndIcons = [
    GraphAPI
   ,Docker
   ,DotNet
   ,SQLServer
 ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Box sx={{ marginTop: '2em', maxWidth: '1390px', marginLeft: 'auto', marginRight: 'auto' }}>

      <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'center', marginBottom: '2em' }}>
        "Empowering innovation through tailored software solutions."
      </Typography>

      <Card sx={{ mx: '5%', marginBottom: 3, border: 1, borderColor: 'primary.main' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', marginBottom: '1em' }}>
            Hochbegabt — "highly gifted or talented"
          </Typography>

          <Typography gutterBottom component="div" sx={{ textAlign: 'center' }}>
            Our mission at Hochbegabt Technology is to collaborate with businesses to transform their ideas into cutting-edge software products that drive growth, efficiency, and success. 
            With a focus on quality, agility, and client satisfaction, we are dedicated to delivering custom solutions that solve problems, propel businesses forward, and make meaningful impacts in the digital landscape.
          </Typography>
        </CardContent>
      </Card>

      <List sx={{ marginTop: '3em', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', height: '100%' }}>
        <ListItem sx={{ width: '400px', marginBottom: 3 }}>
          {/* This color was dynamically changing, but now it doesn't even switch when the SVG is changed, it's always black, so using an inverting filter */}
          <SmallTile icon={<SvgIcon sx={{ fontSize: iconSize, filter: `invert(100%);`}}><Discuss /></SvgIcon>} title="Discuss" link="/about#discuss"
            text="Engaging in discussions about software solutions involves a dynamic process of exploration, analysis, and collaboration to articulate what the software needs to do, and how to accomplish it."></SmallTile>
        </ListItem>
        
        <ListItem sx={{ width: '400px', marginBottom: 3 }}>
          <SmallTile icon={<Create sx={{ fontSize: iconSize }}/>} title="Design" link="/about#design"
            text="Designing software solutions is a multifaceted process that combines creativity, technical expertise, and strategic thinking. A prototype allows a glance at what the final product will look like, allowing quick feedback and iterations with no development"></SmallTile>
        </ListItem>

        <ListItem sx={{ width: '400px', marginBottom: 3 }}>
          <SmallTile icon={<Construction sx={{ fontSize: iconSize }}/>} title="Develop" link="/about#develop"
            text="Developing software solutions is an intricate dance of innovation, collaboration, and technical prowess."></SmallTile>
        </ListItem>
      </List>

      <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>Technologies / Services</Typography>


      <List sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', height: '100%' }}>
        <ListItem sx={{ width: '400px', marginBottom: 3 }}>
          <Card sx={{ width: '400px', marginBottom: 3, border: 1, borderColor: 'primary.main' }}>
            <CardContent>
              <Typography variant="body1" color="text.secondary">
                On the frontend, we harness the power of modern web development frameworks such as React and Angular to create immersive, responsive, and intuitive user interfaces. 
                These frameworks enable us to rapidly prototype and iterate on designs, while ensuring compatibility across a wide range of devices and browsers.
              </Typography>

              <Box sx={{ /*Use if more than 4 icons -> marginBottom: '2em' */ }}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0} flexWrap="wrap">
                  {frontEndIcons.map((Icon, index) => (
                    <Item key={index} sx={{ margin: '1em' }}>
                      <SvgIcon sx={{ fontSize: iconSizeTech }}><Icon/></SvgIcon>
                    </Item>
                  ))}
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </ListItem>
        
        <ListItem sx={{ width: '400px', marginBottom: 3 }}>
          <Card sx={{ width: '400px', marginBottom: 3, border: 1, borderColor: 'primary.main' }}>
            <CardContent>
              <Typography variant="body1" color="text.secondary">
                For backend development, we rely on robust frameworks and platforms such as ASP.NET Core, Docker, and Graph API.
                These frameworks provide a solid foundation for building scalable, performant, and secure backend systems, whether it's a RESTful API, a microservices architecture, or a cloud-native application.
              </Typography>
              <Box sx={{ /*Use if more than 4 icons -> marginBottom: '2em' */ }}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0} flexWrap="wrap">
                  {backEndIcons.map((Icon, index) => (
                    <Item key={index} sx={{ margin: '1em' }}>
                      <SvgIcon sx={{ fontSize: iconSizeTech }}><Icon/></SvgIcon>
                    </Item>
                  ))}
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </ListItem>
      </List>

      {/* TODO: re-enable when necessary — "Our Work" section
      <Card sx={{ mx: '5%', marginBottom: 3, border: 1, borderColor: 'primary.main' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>Our Work</Typography>
          <Typography variant="body2" color="text.secondary">
            Describe systems developed
            <br/><br/>
            Generic wording
            <br/><br/>
            Worked with Fortune 200 companies
            <br/><br/>
            (or generic wording for the companies)</Typography>
        </CardContent>
      </Card>
      */}

    </Box>
  )
}

Home.propTypes = {}

export default Home