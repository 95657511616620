import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Footer.scss'

import { Facebook, Instagram, Twitter, LinkedIn, X, ToysOutlined } from '@mui/icons-material';
import backgroundTest from '../../assets/earth-from-space.jpg';
import logo_white from '../../assets/HT_Horizontal_Wh.svg'
import logo_color from '../../assets/HT_Horizontal_Color_Text.svg'

// MUI components
import { TextField, Button, Typography, Box, FormControl, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { send } from 'emailjs-com';

/** Footer at the bottom of each page that has a contact form and social media links */
const Footer = () => {

  const SERVICE_ID = "service_kx1sqjq";
  const TEMPLATE_ID = "template_unjwjfs";
  const PUBLIC_KEY = "NGGTeRsqIpCieoKvE";

  // Inputs
  const [inputFirstName, setInputFirstName] = React.useState<string>("");
  const [inputLastName, setInputLastName] = React.useState<string>("");
  const [inputEmail, setInputEmail] = React.useState<string>("");
  const [inputPhone, setInputPhone] = React.useState<string>("");
  const [inputCompany, setInputCompany] = React.useState<string>("");
  const [inputProjectDescription, setInputProjectDescription] = React.useState<string>("");

  // Variables
  const [unFormattedPhone, setUnFormattedPhone] = React.useState<string>(""); // The inputPhone is the formatted value, this is unformatted so it's just numbers

  // Dialog variables
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setInputFirstName('');
    setInputLastName('');
    setInputEmail('');
    setInputPhone('');
    setInputCompany('');
    setInputProjectDescription('');
  };


  // On change functions for inputs
  const handleFirstNameInput = (e: any) => {
    setInputFirstName(e.target.value);
    setMessageToSend({ ...messageToSend, firstName: e.target.value});
  }

  const handleLastNameInput = (e: any) => {
    setInputLastName(e.target.value);
    setMessageToSend({ ...messageToSend, lastName: e.target.value});
  }

  const handleEmailInput = (e: any) => {
    setInputEmail(e.target.value);
    setMessageToSend({ ...messageToSend, email: e.target.value});
  }

  const handlePhoneInput = (e: any) => {
    // Format the text to a phone number length 3 3 4
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    // Add in the () and - to become (xxx) xxx-xxxx
    var formattedPhone = e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    
    let unFormattedPhone = e.target.value.replace(/\D+/g, '');
    //console.log(formattedPhone)
    //console.log(unFormattedPhone)

    setInputPhone(formattedPhone);
    setMessageToSend({ ...messageToSend, phone: formattedPhone});
    setUnFormattedPhone(unFormattedPhone) // For passing to DB
  }

  const handleCompanyInput = (e: any) => {
    setInputCompany(e.target.value);
    setMessageToSend({ ...messageToSend, company: e.target.value});
  }

  const handleProjectDescriptionInput = (e: any) => {
    setInputProjectDescription(e.target.value);    
    setMessageToSend({ ...messageToSend, message: e.target.value});
  }

  const [messageToSend, setMessageToSend] = useState({
    firstName: '',
    lastName: '',
    message: '',
    phone: '',
    company: '',
    email: ''
  });

  const submitContactInfo = (event: any) => {
    event.preventDefault();
    send(
      SERVICE_ID,
      TEMPLATE_ID,
      messageToSend,
      PUBLIC_KEY
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    setOpen(true);
  }



  return (
    <Box sx={{ marginTop: '8em', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Paper sx={{ maxWidth: '600px', padding: '1.5em', border: 1, borderColor: 'primary.main' }}>
        <Box id='contact-form'>
          <Typography variant="h5" align='center'>Contact Us!</Typography>

            <FormControl component='form' onSubmit={submitContactInfo} id="contact-info-form" variant='standard' required
              sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr);', gap: '1em' }}>
              <TextField id='first-name'
                name='first-name'
                label='First Name'
                margin='normal'
                value={inputFirstName}
                onChange={handleFirstNameInput}
                required
              />

              <TextField id='last-name'
                name='last-name'
                label='Last Name'
                margin='normal'
                value={inputLastName}
                onChange={handleLastNameInput}
                required
              />

              <TextField id='email'
                name='email'
                label='Email'
                margin='normal'
                type='email'
                value={inputEmail}
                onChange={handleEmailInput}
                required
                inputProps={{ pattern: "[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$" }}
              />

              <TextField id='phone'
                name='phone'
                label='Phone'
                margin='normal'
                type='tel'
                value={inputPhone}
                onChange={handlePhoneInput}
                required
                inputProps={{ pattern: "^\\(\\d{3}\\)\\s\\d{3}-\\d{4}" }}
              />

              <TextField id='company' sx={{ gridColumn: '1 / 3' }}
                name='company'
                label='Company'
                margin='normal'
                value={inputCompany}
                onChange={handleCompanyInput}
              />

              <TextField id='project-description' sx={{ gridColumn: '1 / 3' }}
                name='project-description'
                label='Project Description'
                margin='normal'
                value={inputProjectDescription}
                onChange={handleProjectDescriptionInput}
                multiline
              />

              <Button id='submit-contact-info' type="submit" variant="contained" sx={{ gridColumn: '1 / 3' }}>Submit</Button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Submission successful!"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Thank you for considering partnering with us! We have received your submission and look forward to working with you!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>Ok</Button>
                </DialogActions>
              </Dialog>
            </FormControl>
        </Box>
      </Paper>

      <Box id='social-media' sx={{ marginY: '3em', display: 'flex', justifyContent: 'space-between' }}>
        <Box id='logo'>
          <Button component={Link} to="/">
              <img src={logo_color} alt='Hochbegabt logo' width="125" height="51" title="Hochbegabt Technology" aria-label="hochbegabt-technology"/>
          </Button>
        </Box>

        <Box id='social-media' alignContent='center'>
          {/*
          <IconButton component={Link} to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" title="Facebook" aria-label="facebook"><Facebook /></IconButton>
          <IconButton component={Link} to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" title="Instagram" aria-label="instagram"><Instagram /></IconButton>
          <IconButton component={Link} to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" title="X / Twitter" aria-label="x / twitter"><X /></IconButton>
          <IconButton component={Link} to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" title="LinkedIn" aria-label="linkedin"> <LinkedIn /></IconButton>
          */}
        </Box>
      </Box>
        
    </Box>
  )
}

export default Footer